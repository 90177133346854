import React from 'react';
import { Link } from 'gatsby';
import { _linkResolver, _localizeField } from '../core/utils';
import SanityImage from './SanityImage';
import SanityTexte from './SanityTexte';

const ArticleCard = ({ input }) => (
  <article className="article-card mb-md md:mb-lg ">
    <Link to={_linkResolver(input)}>
      <div className="date font-secondary text-_sm md:text-sm mb-half-1e- mb-[10px] md:mb-sm- leading-4- font-secondary text-primary-50">
        {input.date ? input.date : input.dateFallback}
      </div>
      <div className="image-featured mb-xs md:mb-[14px] pointer-events-none">
        {input.imageFeatured && (
          <SanityImage image={input.imageFeatured} alt={_localizeField(input.title)} />
        )}
        {!input.imageFeatured && (
          <div className="bg-4 p-_sm rounded-md">
            <SanityTexte input={input.texte} />
          </div>
        )}
      </div>
      <div className="card-header">
        <h2 className="">{_localizeField(input.title)}</h2>
      </div>
    </Link>
  </article>
);

export default ArticleCard;
