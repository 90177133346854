import React from 'react';
import SanityTexte from '../SanityTexte';

const QuoteUI = ({ input }) => {
  const { texte, author } = input;
  return (
    <section className="module module-quote-ui ">
      <div className="container">
        <div className="text-_xl md:text-xl italic ">
          <SanityTexte input={texte} />
        </div>
        <div className="text-_sm md:text-sm font-secondary text-primary-50">{author}</div>
      </div>
    </section>
  );
};

export default QuoteUI;
