import React from 'react';
import SanityTexte from '../SanityTexte';

const TextesUI = ({ input }) => {
  const { textes } = input;

  return (
    <section className="module module-textes-ui mb-lg-">
      <div className="container">
        <div className="row !flex-col-reverse md:!flex-row">
          <div className="col-xs-12 col-md-8">
            <SanityTexte input={textes[0].texte} />
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="mb-_md md:mb-0">
              <SanityTexte input={textes[1].texte} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextesUI;
