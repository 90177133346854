import React, { useEffect } from 'react';
import Scrollspy from 'react-scrollspy';

const ScrollSpyNav = ({ input }) => {
  const headlines = input.filter((el) => el._type === 'headlineUI');
  const selectors = headlines.map((el) => `#headline-${el._key}`);
  // console.log(selectors);

  const _onClick = (id) => {
    document.documentElement.classList.add('scroll-smooth');
    // console.log(document.scrollingElement);
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    setTimeout(() => {
      document.documentElement.classList.remove('scroll-smooth');
    }, 1000);
  };

  useEffect(() => {
    _format();
    window.addEventListener('resize', _format);
    return () => {
      window.removeEventListener('resize', _format);
    };
  }, []);

  const _format = () => {
    const spyNavBounding = document.querySelector('.scroll-spy-nav').getBoundingClientRect();
    document.documentElement.style.setProperty('--spy-nav-height', spyNavBounding.height + 'px');
  };

  return (
    <div className="scroll-spy-nav ">
      <Scrollspy
        items={selectors}
        currentClassName="is-active"
        className="toc-list flex flex-wrap pr-md"
      >
        {headlines.map((li, i) => (
          <li key={i} className="pb-2 pr-1 md:pb-3 md:pr-2 ">
            <button
              className="pill text-sm font-secondary bg-secondary hover:bg-secondary-50 active:bg-primary active:text-secondary block has-backdrop-filter"
              onClick={() => _onClick(`headline-${li._key}`)}
              onKeyUp={() => _onClick(`headline-${li._key}`)}
            >
              {li.title}
            </button>
          </li>
        ))}
      </Scrollspy>
    </div>
  );
};

export default ScrollSpyNav;
