import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { publish, subscribe, unsubscribe } from 'pubsub-js';
import { useRef } from 'react';
// import { _localizeText } from "../../core/utils"

const DropDownButton = styled.button`
  text-align: left;
  position: relative;
  &.has-chevron .summary--title {
    padding-left: calc(var(--space-xs) + var(--text-_sm));

    @media all and (min-width: 1080px) {
      padding-left: calc(var(--space-xs) + var(--text-sm));
    }
  }
  .summary--icon {
    top: 1em;
    font-size: 7px;
    @media all and (min-width: 1080px) {
      top: 1.6em;
      font-size: 8px;
    }

    transform-origin: center;
    transition: transform 0.2s;
    transform: ${(props) =>
      props.collapsed ? 'translateY(0%) rotate(0deg)' : 'translateY(0%) rotate(180deg)'};
  }
  .col-md-3:first-child h3 {
    // color: red;
    padding-left: 1em;
  }
`;
const DropDownContent = styled.div`
  width: 100%;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s, opacity 0.3s; // note that we're transitioning max-height, not height!
  transition-timing-function: ease;
  height: auto;
  max-height: ${(props) => (props.maxHeight ? `${props.maxHeight}px` : '500px')};
  /* max-height: 500px; // still have to hard-code a value! */
  @media all and (max-width: 1080px) {
    max-height: 100vh; // still have to hard-code a value!
  }
  &.is-collapsed {
    max-height: 0;
    opacity: 0;
  }
`;

const SummaryDetail = ({ id = '', hasChevron = true, summary, detail, onOpen }) => {
  const [collapsed, setCollapsed] = useState(true);
  const [maxHeight, setMaxHeight] = useState();

  const contentRef = useRef();
  useEffect(() => {
    const token = subscribe('SUMMARY_OPEN', (e, d) => {
      // console.log(d.id, id);
      if (d.id !== id) {
        setCollapsed(true);
      }
    });
    return () => unsubscribe(token);
  }, [id]);

  useEffect(() => {
    if (!collapsed) {
      if (typeof onOpen === 'function') onOpen();
    } else {
      publish('SUMMARY_CLOSE');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  useEffect(() => {
    const { height } = contentRef.current.getBoundingClientRect();
    // console.log(height);
    if (height) setMaxHeight(height);
  }, []);

  return (
    <div className="summary-detail" id={id}>
      <DropDownButton
        className={clsx('summary w-full', hasChevron ? 'has-chevron mb-xs md:mb-0' : '')}
        collapsed={collapsed}
        onClick={() => setCollapsed(!collapsed)}
      >
        {hasChevron && (
          <div className="summary--icon icon-mark absolute">
            {/* <span className="icon-chevron"></span> */}
            <i className="icon-chevron-s"></i>
          </div>
        )}
        <div className="summary--title">{summary}</div>
      </DropDownButton>
      <DropDownContent
        className={clsx('detail', collapsed ? 'is-collapsed' : '')}
        maxHeight={maxHeight}
      >
        <div className="inner" ref={contentRef}>
          {detail}
        </div>
      </DropDownContent>
    </div>
  );
};

export default SummaryDetail;
