import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Modules from '../components/modules';
// import Hero from '../components/ui/Hero';
// import WeatherWidget from '../components/ui/WeatherWidget';
import { _localizeField } from '../core/utils';
import ScrollSpyNav from '../components/ScrollSpyNav';
import clsx from 'clsx';
// import ScrollDown from '../components/ui/ScrollDown';
import SliderHero from '../components/SliderHero';

// import clsx from "clsx"

export const query = graphql`
  query PageBySlug($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      id
      seo {
        ...seo
      }
      slug {
        current
      }
      title {
        ...localeString
      }
      # imageHero: _rawImageHero(resolveReferences: { maxDepth: 1 })
      background
      sliderHero {
        slides {
          ... on SanityImage {
            _key
            _type
            asset: _rawAsset(resolveReferences: { maxDepth: 10 })
          }
          ... on SanityVideo {
            _key
            _type
            url
          }
        }
      }
      modules {
        ... on SanityHeadlineUI {
          _key
          _type
          title
          hn
        }
        ... on SanityTexteUI {
          _key
          _type
          texte {
            ...localeBlockContent
          }
        }
        ... on SanityTextesUI {
          _key
          _type
          textes {
            texte {
              ...localeBlockContent
            }
          }
        }
        ... on SanityImageUI {
          _key
          _type
          image: _rawImage(resolveReferences: { maxDepth: 20 })
          expandable
          # image {
          #   asset {
          #     image: gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          #     metadata {
          #       dimensions {
          #         aspectRatio
          #         height
          #         width
          #       }
          #     }
          #   }
          # }
          caption {
            ...localeString
          }
        }
        ... on SanityImagesUI {
          _key
          _type
          images {
            image: _rawImage(resolveReferences: { maxDepth: 10 })
            caption: _rawCaption(resolveReferences: { maxDepth: 10 })
            expandable
          }
        }
        ... on SanityQuoteUI {
          _key
          _type
          texte {
            ...localeBlockContent
          }
          author
        }
        ... on SanityTrombinoscopeUI {
          _key
          _type
          members {
            name
            role
            bio
            image: _rawImage(resolveReferences: { maxDepth: 10 })
            cv {
              asset {
                url
              }
            }
          }
        }
        ... on SanitySliderUI {
          _key
          _type
          title
          images {
            ... on SanityImageCard {
              _type
              image: _rawImage(resolveReferences: { maxDepth: 10 })
              caption: _rawCaption(resolveReferences: { maxDepth: 10 })
              expandable
              title {
                ...localeString
              }
            }
            ... on SanityProjet {
              _type
              ...projetCard
            }
          }
        }
        ... on SanitySliderDyptiqueUI {
          _key
          _type
          images {
            ... on SanityImageCard {
              _type
              image: _rawImage(resolveReferences: { maxDepth: 10 })
              caption: _rawCaption(resolveReferences: { maxDepth: 10 })
              title {
                ...localeString
              }
              expandable
            }
            ... on SanityProjet {
              _type
              ...projetCard
            }
          }
        }
        ... on SanitySliderMosaicUI {
          _key
          _type
          title
          slides {
            items {
              ... on SanityTexteCard {
                _type
                color {
                  hex
                }
                texte {
                  ...localeString
                }
              }
              ... on SanityImageCard {
                _type
                image: _rawImage(resolveReferences: { maxDepth: 10 })
                imageData: image {
                  asset {
                    gatsbyImageData(width: 800)
                    metadata {
                      palette {
                        dominant {
                          background
                        }
                      }
                    }
                  }
                }
                caption: _rawCaption(resolveReferences: { maxDepth: 10 })
                expandable
              }
            }
          }
        }
        ... on SanityNewsUI {
          _key
          _type
          articles {
            _type
            date(formatString: "DD.MM.yyyy")
            dateFallback: _createdAt(formatString: "DD.MM.yyyy")
            title {
              fr
            }
            slug {
              current
            }
            imageFeatured: _rawImageFeatured(resolveReferences: { maxDepth: 10 })
            texte {
              ...localeBlockContent
            }
          }
        }
        ... on SanitySpacer {
          _key
          _type
          size
        }
      }
    }
  }
`;

const Page = ({ pageContext, data }) => {
  // console.log(pageContext);
  const { seo, slug, title, background, sliderHero, modules } = data.sanityPage;
  const hasHero = sliderHero;
  return (
    <div className={clsx('main-area', !hasHero ? 'pt-header-height' : '')}>
      {seo && (
        <Seo
          metaTitle={seo.metaTitle}
          metaDescription={seo.metaDescription}
          metaImage={seo.metaImage?.asset.url}
          template={`template-page page-${slug.current} ${background ? `bg-${background}` : ''}`}
          page={true}
        />
      )}

      {sliderHero && (
        <div className="mb-_md md:mb-md">
          <SliderHero input={sliderHero} />
        </div>
      )}

      <div className="header  ">
        <div className="container">
          <h1 className="mb-half-1e">{_localizeField(title)}</h1>
        </div>
      </div>

      <div className="nacv mb-lg sticky top-header-mid z-10">
        <div className="container">
          <ScrollSpyNav input={modules} />
        </div>
      </div>

      {modules && <Modules input={modules} />}
    </div>
  );
};

export default Page;
