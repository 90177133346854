import React from 'react';
import SanityImage from '../SanityImage';
// import SanityTexte from '../SanityTexte';
import styled from 'styled-components';
import SummaryDetail from '../ui/SummaryDetail';

const Section = styled.section`
  .item {
    border-top: 1px solid;

    line-height: 1.2;
    padding-bottom: 8px;
    .summary {
      padding-top: 8px;
    }
    .detail {
      z-index: 2;
      .image {
        aspect-ratio: 11 / 15;
      }
    }
    @media all and (min-width: 1080px) {
      padding-bottom: 15px;
      .summary {
        padding-top: 15px;
      }
    }
  }
`;

const TrombiItem = ({ input }) => (
  <div className="item">
    <SummaryDetail
      hasChevron={false}
      summary={
        <div className="header">
          <div className="">{input.name}</div>
          <div className="text-primary-50 mb-1e-">{input.role}</div>
        </div>
      }
      detail={
        <div className="content py-1e">
          <div
            className="row custom-gutter-xs"
            style={{
              '--custom-gutter': '10px',
            }}
          >
            {input.image && (
              <div className="col-xs-4 col-md-4">
                <div className="mb-_sm md:mb-0 image">
                  <SanityImage image={input.image} expandable={false} />
                </div>
              </div>
            )}
            <div className="col-md-8 col-xs-8">
              <div className="h-full flex flex-col justify-between">
                <p>{input.bio}</p>
                {input.cv && (
                  <div>
                    <a
                      href={input.cv.asset.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pill bg-secondary-50 inline-block text-sm"
                      download={true}
                    >
                      Télécharger le CV complet
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    />
  </div>
);

const TrombinoscopeUI = ({ input }) => {
  const half = Math.ceil(input.members.length / 2);
  const firstHalf = input.members.slice(0, half);
  const secondHalf = input.members.slice(half, input.members.length);
  // console.log(half);
  return (
    <Section className="module module-trombinoscope-ui mb-lg- font-secondary text-_sm md:text-sm">
      <div className="container">
        <div className=" md:grid md:grid-cols-2 gap-md">
          <div className="w-full md:w-1/2- pr-md-">
            {firstHalf.map((item, i) => (
              <div className="---" key={i}>
                <TrombiItem input={item} />
              </div>
            ))}
          </div>

          <div className="w-full md:w-1/2- pr-sm-">
            {secondHalf.map((item, i) => (
              <div className="---" key={i}>
                <TrombiItem input={item} />
              </div>
            ))}
          </div>
        </div>
        {/* <code>{JSON.stringify(input, null, '\t')}</code> */}
      </div>
    </Section>
  );
};

export default TrombinoscopeUI;
