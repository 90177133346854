import React from 'react';
import ArticleCard from '../ArticleCard';
import AnimateOnScrollFramer from '../ui/AnimateOnScrollFramer';

const NewsUI = ({ input }) => {
  return (
    <section className="module module-news-ui mb-lg">
      <div className="container">
        <div className="news-grid">
          <div
            className="row justify-end custom-gutter-"
            // style={{
            //   '--custom-gutter': '20px',
            // }}
          >
            {input.articles.map((item, i) => (
              <div className={'col-md-6 col-xs-12'} key={i}>
                <AnimateOnScrollFramer>
                  <ArticleCard input={item} />
                </AnimateOnScrollFramer>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsUI;
