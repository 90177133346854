import React from 'react';
import styled from 'styled-components';
import Slider from './ui/slick-slider';
import SanityImage from './SanityImage';
import VideoPlayer from './ui/VideoPlayer';

const Container = styled.div`
  color: white;
  .slick-track {
    display: block;
  }
  .gatsby-image-wrapper,
  img,
  .video-player,
  iframe {
    width: 100vw;
    height: 100vh;
    vertical-align: top;
  }
  /* iframe {
    height: auto !important;

    object-fit: cover;
    background-color: black;
    aspect-ratio: 16 / 9;

  } */

  .player > div {
    pointer-events: none;
    overflow: hidden;
    position: relative;
    height: calc(100vh - 180px);
    padding: 0;
  }

  @media (min-width: 1000px) {
    .player > div {
      height: 100vh !important;
    }
  }

  .player > div iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  @media (min-aspect-ratio: 16 / 9) {
    .player > div iframe {
      height: 56.25vw !important;
    }
  }

  @media (max-aspect-ratio: 16 / 9) {
    .player > div iframe {
      width: 177.78vh !important;
    }
  }

  @media (max-width: 1080px) {
    max-height: 550px;
  }
`;

const SliderHero = ({ input }) => {
  const settings = {
    speed: 1000,
    autoplaySpeed: 5000,
    // autoplay: true,
  };
  // console.log(settings);
  const isSingleSlide = input.slides.length === 1;
  return (
    <Container className="slider-hero h-screen w-screen max-w-full overflow-hidden">
      {isSingleSlide ? (
        <div className="slide">
          {input.slides[0]._type === 'image' && (
            <SanityImage image={input.slides[0]} objectFit="cover" alt="" />
          )}
          {input.slides[0]._type === 'video' && <VideoPlayer url={input.slides[0].url} />}
        </div>
      ) : (
        <Slider settingsOverride={settings} hasBlur={false}>
          {input.slides.map((item, i) => (
            <div className="slide max-w-full" key={i}>
              {item._type === 'image' && <SanityImage image={item} objectFit="cover" alt="" />}
              {item._type === 'video' && <VideoPlayer url={item.url} />}
            </div>
          ))}
        </Slider>
      )}

      {/* <pre>{JSON.stringify(input)}</pre> */}
    </Container>
  );
};

export default SliderHero;
